import { Draggable, Droppable } from "@hello-pangea/dnd";
import clsx from "clsx";
import _ from "lodash";
import { useSearchParams } from "react-router";
import { Tooltip } from "react-tooltip";
import Badge from "~/components/badge";
import type { ComboBoxOption } from "~/components/combobox";
import ContentCard from "~/components/content/card";
import { IconAdd, IconHelp } from "~/components/icons";
import Card from "~/components/kanban/card";
import Link, { useLocationPusher } from "~/components/link";
import type {
  ContentTopicListFieldsFragment,
  DeliverableFieldsFragment,
  KanbanCardFieldsFragment
} from "~/types/api";
import { useCurrentUser } from "~/utils/auth";
import { dayIsBefore } from "~/utils/dates";
import { plural } from "~/utils/formatting";
import { ClientOnly } from "~/utils/remix";

type Props = {
  cards: (KanbanCardFieldsFragment | ContentTopicListFieldsFragment)[];
  showProject?: boolean;
  deliverable?: DeliverableFieldsFragment;
  panel?: boolean;
  showOld: "toggle" | "redirect";
  onAdd?: (column: string) => void;
  column: string;
  hideUser?: boolean;
  groupBy?: string | null;
  openBacklog?: () => void;
  showFullBacklog?: boolean;
  label?: string;
  showHours?: boolean;
  columnDescriptions?: ComboBoxOption[];
  dropDisabled?: boolean;
  mode: "KanbanCard" | "ContentTopic";
};

const MAX_BACKLOG = 10;

export default function Column({
  cards,
  showOld,
  showProject,
  deliverable,
  panel,
  onAdd,
  column,
  hideUser,
  openBacklog,
  label = "Cards",
  showHours = true,
  showFullBacklog,
  columnDescriptions,
  dropDisabled,
  mode
}: Props) {
  const [params] = useSearchParams();
  const showingOld = params.get("completed-cards") === "true";
  const push = useLocationPusher();
  const currentUser = useCurrentUser();

  const hours = _.sumBy(cards, (c) =>
    Number.parseFloat(c.__typename === "KanbanCard" ? c.hours || "0" : "0")
  );

  // Don't show could/should do columns if they are empty
  if (["Could Do", "Should Do", "Urgent"].includes(column) && !cards.length)
    return null;

  const description = columnDescriptions?.find((d) => d.value === column)
    ?.subtitle as string;
  const hideBacklog = cards.length > MAX_BACKLOG && !showFullBacklog;
  const filtered =
    column === "Backlog" && cards.length > 10 && !showFullBacklog
      ? currentUser.kanbanShowTopBacklog
        ? cards.slice(0, 10)
        : []
      : column === "Done" && mode === "KanbanCard"
        ? _.orderBy(cards, "completedAt", "desc")
        : cards;
  const pastDueBacklog =
    column === "Backlog"
      ? cards.filter(
          (c) =>
            c.__typename === "KanbanCard" &&
            c.dueDate &&
            dayIsBefore(c.dueDate, new Date())
        ).length
      : 0;
  return (
    <div
      className={clsx(
        "flex min-w-[100px] flex-1 flex-col overflow-hidden border-gray-200",
        panel ? "px-2" : "px-4"
      )}
    >
      <div className="flex flex-wrap items-center justify-between gap-x-2 gap-y-1 pt-4 text-2xl font-bold leading-normal text-gray-700">
        <span className="flex-1 flex items-center gap-2">{column}</span>

        {column === "Published" && (
          <span className="text-xl leading-normal">
            <Link to="/content/planning/published">Show All</Link>
          </span>
        )}
        {column === "Backlog" && filtered.length < cards.length && (
          <span className="text-xl leading-normal">
            <Link to={() => openBacklog?.()}>Show All</Link>
          </span>
        )}
        {column === "Done" && (
          <span className="text-xl leading-normal">
            {showOld === "toggle" ? (
              <a
                onClick={() =>
                  push(
                    { "completed-cards": showingOld ? undefined : true },
                    { replace: true }
                  )
                }
              >
                {showingOld ? "Show Recent" : "Show All"}
              </a>
            ) : (
              <Link to="/kanban/completed">Show All</Link>
            )}
          </span>
        )}
        {onAdd && (
          <span className="text-gray-500">
            <Link to={() => onAdd(column)}>
              <IconAdd />
            </Link>
          </span>
        )}
        {description && (
          <span data-tooltip-id={`column-${column}`}>
            <IconHelp className="text-gray-400" />
            <ClientOnly>
              {() => (
                <Tooltip
                  id={`column-${column}`}
                  place="bottom"
                  content={description}
                  className="max-w-[400px] !text-xl !font-normal"
                />
              )}
            </ClientOnly>
          </span>
        )}
      </div>
      <div className="bg-gray-100 pb-4 pt-1 text-[12px] font-normal text-gray-600 flex items-center gap-2">
        {column === "Done" && mode === "KanbanCard" ? (
          <div className="italic text-gray-500">
            Done now sorts by recently completed
          </div>
        ) : (
          <>
            <div className="flex-1">
              {plural(label, cards.length, true)}
              {showHours && (
                <span>
                  {" "}
                  / {plural("Hours", hours, true, { format: "0,0.0" })}
                </span>
              )}
            </div>
            {pastDueBacklog > 0 && (
              <Badge mode="danger">
                {plural("Past Due Card", pastDueBacklog, true)}
              </Badge>
            )}
          </>
        )}
      </div>

      {column === "Done" && mode === "KanbanCard" ? (
        <Droppable droppableId={column} isDropDisabled={dropDisabled}>
          {(dropProvided, dropSnapshot) => (
            <>
              <div
                className={clsx(
                  dropSnapshot.isDraggingOver && "bg-gray-200",
                  "flex-1 relative rounded-md transition-colors duration-300 ease-out",
                  !panel && "pb-8"
                )}
                {...dropProvided.droppableProps}
                ref={dropProvided.innerRef}
              >
                {dropSnapshot.isDraggingOver && (
                  <div className="absolute top-0 left-0 right-0 bottom-0 bg-gray-500/30 z-200" />
                )}
                {dropProvided.placeholder}

                <InnerColumn
                  column={column}
                  mode={mode}
                  filtered={filtered}
                  cards={cards}
                  hideBacklog={hideBacklog}
                  hideUser={hideUser}
                  panel={panel}
                  openBacklog={openBacklog}
                  deliverable={deliverable}
                  showProject={showProject}
                />
              </div>
            </>
          )}
        </Droppable>
      ) : (
        <InnerColumn
          column={column}
          mode={mode}
          filtered={filtered}
          cards={cards}
          hideBacklog={hideBacklog}
          hideUser={hideUser}
          panel={panel}
          openBacklog={openBacklog}
          deliverable={deliverable}
          showProject={showProject}
        />
      )}
    </div>
  );
}

type InnerProps = {
  cards: (KanbanCardFieldsFragment | ContentTopicListFieldsFragment)[];
  column: string;
  deliverable?: DeliverableFieldsFragment;
  filtered: (KanbanCardFieldsFragment | ContentTopicListFieldsFragment)[];
  hideBacklog?: boolean;
  hideUser?: boolean;
  mode: "KanbanCard" | "ContentTopic";
  openBacklog?: () => void;
  panel?: boolean;
  showProject?: boolean;
};

function InnerColumn({
  cards,
  column,
  deliverable,
  filtered,
  hideBacklog,
  hideUser,
  mode,
  openBacklog,
  panel,
  showProject
}: InnerProps) {
  return (
    <Droppable
      droppableId={
        column === "Done" && mode === "KanbanCard" ? "Done-sorted" : column
      }
      isDropDisabled={column === "Done" && mode === "KanbanCard"}
    >
      {(dropProvided, dropSnapshot) => (
        <div
          className={clsx(
            dropSnapshot.isDraggingOver && "bg-gray-300",
            "flex-1 rounded-md transition-colors duration-300 ease-out",
            !panel && "pb-8"
          )}
          {...dropProvided.droppableProps}
          ref={dropProvided.innerRef}
        >
          {filtered.map((card, index) => (
            <Draggable key={card.id} draggableId={card.id} index={index}>
              {(dragProvided, dragSnapshot) =>
                card.__typename === "KanbanCard" ? (
                  <Card
                    key={card.id}
                    card={card}
                    index={index}
                    deliverable={deliverable}
                    noDeliverable={!!deliverable}
                    noProject={!showProject}
                    hideUser={hideUser}
                    provided={dragProvided}
                    isDragging={dragSnapshot.isDragging}
                  />
                ) : (
                  <ContentCard
                    key={card.id}
                    topic={card}
                    provided={dragProvided}
                    isDragging={dragSnapshot.isDragging}
                  />
                )
              }
            </Draggable>
          ))}
          {["Backlog"].includes(column) && (
            <div className="mb-4 space-y-4 italic text-gray-500">
              <div>
                The backlog is where all future work lives until it's ready to
                be worked on. If something isn't being worked on now or in the
                near future, it should go here.
              </div>
            </div>
          )}
          {hideBacklog && column === "Backlog" && !filtered.length
            ? null
            : dropProvided.placeholder}
          {hideBacklog && column === "Backlog" && (
            <div className="mb-4 space-y-4 italic text-gray-500">
              {!filtered.length
                ? "The backlog has too many cards to display here. "
                : cards.length - filtered.length === 1
                  ? "There is one more card. "
                  : `There are ${cards.length - filtered.length} more cards... `}
              <Link to={() => openBacklog?.()}>View Full Backlog</Link>
            </div>
          )}
        </div>
      )}
    </Droppable>
  );
}
