import type { DraggableProvided } from "@hello-pangea/dnd";
import { useSearchParams } from "react-router";
import FormattedDate from "~/components/formatted-date";
import DraggableCard from "~/components/kanban/draggable-card";
import Link from "~/components/link";
import type { ContentTopicListFieldsFragment } from "~/types/api";

type Props = {
  topic: Pick<
    ContentTopicListFieldsFragment,
    "owner" | "id" | "title" | "statusRank" | "status" | "publishedOn"
  >;
  provided: DraggableProvided;
  isDragging: boolean;
};

export default function ContentCard({ topic, provided, isDragging }: Props) {
  const [params] = useSearchParams();
  return (
    <DraggableCard provided={provided} isDragging={isDragging} id={topic.id}>
      <Link to={`/content/${topic.id}`}>{topic.title}</Link>
      {(topic.publishedOn || topic.owner) && (
        <div className="flex justify-between space-x-2">
          <div>
            {topic.publishedOn && (
              <>
                Published <FormattedDate date={topic.publishedOn} />
              </>
            )}
          </div>
          <div>{topic.owner?.fullname}</div>
        </div>
      )}
      {params.get("debug") === "true" && topic.statusRank}
    </DraggableCard>
  );
}
